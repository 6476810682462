export default {
  masterChef: {
    97: '0x0cE117E5De1223fe6CcD8b5F7A2988dE3e060820',
    56: '0x5FB3C407db24DDaD5beddFbc65d8E8D467de1da9',
  },
  masterChefOld: {
    97: '0xe798a07E4F8C1736b5Ce2f1268f2d6698D188B63',
    56: '0xf5F8f426ca29535e1147598900E3378F2A9594A9',
  },
  sousChef: {
    97: '0x7B8e2CE337cfEad3633EeB8dDDC242e76fF1A613',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  signatureFactory: {
    97: '0x817aBb32A0D79fEAf8FB09169D1725B5826D7EA1',
    56: '0x126a56472b597930Eb41c153F0956016322f97bB',
  },
  signatureMintProxy: {
    97: '0x71754D13216C4Ab352CeE176CB1cfeEc791F90C6',
    56: '0x3B00eD49b63eEaFF4D7e8AD664a557204c65A4e6',
  },
  signatureReward: {
    97: '0x66aeBdf8C24eEe2741BD4Fb8353380AcB993f30f',
    56: '0xD96D059478455784833114Cc96115dC214C027a0',
  },
  nftFactory: {
    97: '0x3E072f99aE6086de6C9d3B7e88C4D86532dFA224',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  nftMintProxy: {
    97: '0xC4480C593408e75E1691DfbFBE072B14E6a76937',
    56: '0x09528A8DC7c7A80db940783bC737C81916AB659e',
  },
  generalNFTReward: {
    97: '0x5FB3C407db24DDaD5beddFbc65d8E8D467de1da9',
    56: '0x2395d1752389F7323D8B7a92917702b4eF7e412C',
  },
  generalNFTRewardV2: {
    97: '0x32eef3E74a283ac13F2b80Ad5Bc579691443840b',
    56: '0x05Da79fCA22D55C01c4F7F37e6332692736621d1',
  },
  generalNFTRewardV3: {
    97: '0xEAA8643a81B2F72fB887BCe47f8B97FE91e45567',
    56: '0x483a789Ff6F4f62442fD1e7933172a34573aD219',
  },
  generalNFTRewardV4: {
    97: '0x12F9DF23Fd7eCaC7ca6138cE04BC65D418717a16',
    56: '0x631e57b683537A19B2Bff968D742587672211D51',
  },
  nftMarketplace: {
    97: '0xd86Fd2D49806b4415cB209765d70a7F2fF546499',
    56: '0xdA05472a209Efdc1494387e405E230f364da61a3',
  },
  governance: {
    97: '0x1eA385F7a69Bf8ae598424C339247B638A8fCa86',
    56: '0x5c8d87435bcd8582dc01c8103737791f798a1520',
  },
  communityGovernance: {
    97: '0x24c58c42e2bca6d6d172E6bdc0f673225DF3207B',
    56: '0xD63437fad823315E5bD66a91C0f522eaED307460',
  },
  admin: {
    97: '0x605a4B1A8a7584b4699a1B90E0783997F8855285',
    56: '0xC0e82D18B54038c100269C913A63FD973ea18404',
  },
  saleFactory: {
    97: '0xc774f3b3Fd07A9678988aB739022f2625F142890',
    56: '0x67e3b7b5d23b7e51f567E486F8987bdf6790DD00',
  },
  adminV2: {
    97: '0xb0EC0dC41FBC077aC7A10Ff2d95caC4b965dEeeD',
    56: '0x0B00b1519701D3B903c9CD9e03bf507CACfB3098',
  },
  swapPoolFactory: {
    97: '0x3221475807c9B4487De0D3D7813AF36A25bcABA4',
    56: '0xA1D0cc096D848bd1bEb78caaC3DA1AfFA86Bc499',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    // 97: '0xfb3ad4656eE1Dd1687c0F2007335a0eC9a02E851',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  referral: {
    56: '0x0C4EbFbD7d8F881242cd7d32B3e3101a6986636B',
    97: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
  },
}