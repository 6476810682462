import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getOldMasterChefAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[], isOld = false) => {
  const masterChefAddress = isOld ? getOldMasterChefAddress() : getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserInfos = async (account: string, farmsToFetch: SerializedFarmConfig[], isOld = false) => {
  const masterChefAddress = isOld ? getOldMasterChefAddress() : getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid || farm.pid === 0 ? farm.pid : 2, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  const parsedNextHarvestUntils = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[3]._hex).toNumber()
  })
  const parsedLockedAmounts = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[2]._hex).toJSON()
  })
  return [parsedStakedBalances, parsedNextHarvestUntils, parsedLockedAmounts]
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[], isOld = false) => {
  const masterChefAddress = isOld ? getOldMasterChefAddress() : getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingSpy',
      params: [farm.pid || farm.pid === 0 ? farm.pid : 2, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}